import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { fetchData } from "./redux/data/dataActions";
import './App.css';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  function truncateWalletAddress(address) {
    if (address.length <= 10) {
      return address;
    }
    return address.slice(0, 5) + "..." + address.slice(-3);
  }

export const ImageButton = ({ href, imgSrc, alt }) => (
    <a href={href}>
      <img src={imgSrc} alt={alt} className="me-2 img-button small-image" />
    </a>
  );

  function getColorByLength(wordLength) {
    switch (wordLength) {
      case 1:
        return "#7F7F7F";
      case 2:
        return "#727B84";
      case 3:
        return "#5E8D96";
      case 4:
        return "#A0B5BA";
      case 5:
        return "#6A8174";
      case 6:
        return "#8B997A";
      case 7:
        return "#BAB37F";
      case 8:
        return "#BF8A67";
      case 9:
        return "#B27F8B";
      case 10:
        return "#8888AA";
      case 11:
        return "#BEBDF9";
      default:
        return "#7F7F7F";
    }
  }
  
  function getColoredFeedback(feedback) {
    const words = feedback.split(" ");
    return words.map((word) => {
      const color = getColorByLength(word.length);
      return (
        <span key={word} style={{ color: color, marginRight: '4px' }}>
          {word}
        </span>
      );
    });
  }

  function getColoredWordsByLength(word) {
      const color = getColorByLength(word.length);
      return (
        <span key={word} style={{ color: color, marginRight: '4px' }}>
          {word}
        </span>
      );
  }
  

function App() {
  

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [walletAddress, setWallet] = useState("Connect");
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("1 VOCAL GHOST COST 0.005 ETH");
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME} token(s)`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .Mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("You have rejected the transaction , please try again!");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations! you have minted ${mintAmount}  ${CONFIG.NFT_NAME} tokens!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 11) {
      newMintAmount = 11;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setWallet(blockchain.account);
      var div = document.getElementById("MintCount");
      div.style.display="inline";
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="bg-black">
    <Container fluid>
      <Row className="align-items-center py-2">
        <Col>
          <a href="/">
            <img src="images/logo.png" alt="Your Logo" className="logo logo-image" />
          </a>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button className="me-2 rounded-btn" variant="outline-light" onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                        
                      }} >{getColoredWordsByLength(truncateWalletAddress(walletAddress))}</Button>
         
                    
          <ImageButton href="https://etherscan.io/address/0xa5de45b706d257179612d9cb640b2905ee8e94db" imgSrc="images/etherscan.png" alt="Page 1" />
          <ImageButton href="https://twitter.com/vocalghostnft" imgSrc="images/twitter.png" alt="Page 2" />
          <ImageButton href="https://opensea.io/collection/vocalghost" imgSrc="images/opensea.png" alt="Page 3" />
        </Col>
      </Row>
    </Container>

    <Container className="text-center d-flex flex-column justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 56px)' }}>
    <div id="MintCount" style={{display: 'none'}} >
    <p id="feedback">{getColoredWordsByLength(data.totalSupply)} / {getColoredWordsByLength(CONFIG.MAX_SUPPLY)}</p>
    </div>
    <br></br>
    <br></br>
    <p id="feedback">{getColoredFeedback(feedback)}</p> <br></br> <br></br>
    <div className="d-flex">
  
      <Button className="rounded-btn3" variant="outline-light" 
      disabled={claimingNft ? 1 : 0}
      onClick={(e) => {
        e.preventDefault();
        decrementMintAmount();
      }}>
        -
      </Button>
      <div className="mx-3 counter-number">{mintAmount}</div>
      <Button className="rounded-btn3" variant="outline-light" 
      disabled={claimingNft ? 1 : 0}
      onClick={(e) => {
        e.preventDefault();
        incrementMintAmount();
      }}>
        +
      </Button>
    </div>
    
      <Button className="mt-3 rounded-btn2"  variant="outline-light"
       disabled={claimingNft ? 1 : 0}
       onClick={(e) => {
        if(walletAddress != "Connect"){
         e.preventDefault();
         claimNFTs();
         getData();
        }
       }}
       
      >{claimingNft ? getColoredFeedback("Minting") : getColoredFeedback("Mint")}</Button>
    </Container>

  </div>
  );
}

export default App;
